$(() => {
  if (!$('.login-page').length) {
    return;
  }

  function showWelcomeBackMessageForExistingUsers() {
    if (location.href.includes('email=')) {
      window.notifyInfo($('.login-page').data('existing-account-notification-text'));
      window.history.replaceState({}, '', '/'); // remove any parameters from the url
    }
  }

  showWelcomeBackMessageForExistingUsers();

  window.lottie.loadAnimation({
    container: document.getElementById('login_animation_container'),
    renderer: 'svg',
    loop: true,
    autoplay: true,
    path: '/animations/login_animation.json'
  });
});
