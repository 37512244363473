$(() => {
  if (!$('.login-existing-user-page').length) {
    return;
  }

  function showWelcomeBackMessageForExistingUsers() {
    if (location.href.includes('email=')) {
      window.notifyInfo($('.login-existing-user-page').data('existing-account-notification-text'));
    }
  }

  showWelcomeBackMessageForExistingUsers();

  $('.header-login-button').hide();
});
