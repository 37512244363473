$(() => {
  const loginErrorNotification = window.createErrorNotification();

  const togglePasswordVisibility = (event) => {
    const button = $(event.currentTarget);
    const input = button.parent().find('input');

    const isVisible = button.hasClass('visible');

    button.toggleClass('visible', !isVisible);
    input.attr('type', isVisible ? 'password' : 'text');
  };

  const handleSignInFormSubmit = (event) => {
    event.preventDefault();

    const form = $(event.currentTarget);
    const email = form.find('input[name="user[email]"]').val();
    const password = form.find('input[name="user[password]"]').val();

    if (!email || !password) {
      return;
    }

    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: form.attr('action'),
      data: form.serializeArray()
    })
      .done((response) => {
        loginErrorNotification.remove();
        if (response.location) location.href = response.location;
      })
      .fail((response) => {
        loginErrorNotification.showMessage(response.responseJSON.error);
        form.find('input[type="submit"]').removeAttr('disabled');
      });
  };

  const focusSignInForm = () => {
    $('#user_email').trigger('focus');
  };

  $(document.body)
    .on('click', '.password-visibility-button', togglePasswordVisibility)
    .on('click', '.header-login-button', focusSignInForm)
    .on('submit', '#sign-in-form', handleSignInFormSubmit);
});
